import React from 'react'
import { Link } from "gatsby"
// import logo from '../images/nuerex_logo.png'
var date = new Date();
var year = date.getFullYear();

const Footer = () => (
    <>
    <section id="footer" className="section footer">
        <div className="container">
          <div className="top-part">
            <div className="columns">
              {/* <div className="column subscribe">
                  
                  <p>Join 16,000 other people and get the latest updates on business, 
                    technology and digital to help improve your business.</p>
                  <Link to="/" className="button green-border">Subscribe</Link>
              </div> */}
              {/* <div className="column menu">
                <h6 className="menu-title">Services</h6>
                  <ul>
                    <li><Link to="/business-consulting">Business Consulting</Link></li>
                    <li><Link to="/technology-services">Technology Services</Link></li>
                    <li><Link to="/digital-consulting-services">Digital Consulting</Link></li>
                    <li><Link to="/industry-expertise">Industries</Link></li>
                  </ul>
              </div> */}

              <div className="column menu">
                <h6 className="menu-title">Company</h6>
                  <ul>
                    <li><Link to="/about-us">About Monera Technologies</Link></li>
                    <li><Link to="/blog">News & Blog</Link></li>
                    <li><Link to="/contact-us">Contact</Link></li>
                  </ul>
              </div>
              
              <div className="column menu">
                <h6 className="menu-title">Social</h6>
                  <ul>
                    <li><Link to="/">Facebook</Link></li>
                    <li><Link to="/">Twitter</Link></li>
                    <li><Link to="/">Linkedin</Link></li>
                  </ul>
              </div>
              <div className="column menu">
                <h6 className="menu-title">Contact</h6>
                  <ul>
                    <li className="with-icon"><i className="address"></i><a href="https://www.google.com/maps/place/4601+Fairfax+Dr+Suite+1200,+Arlington,+VA+22203,+USA/@38.8826504,-77.1202361,17z/data=!3m1!4b1!4m5!3m4!1s0x89b7b43a48957723:0x32bc4ba11224b321!8m2!3d38.8826504!4d-77.1180474" target="_blank">4601 N. Fairfax Dr. Suite 1200 Arlington VA, 22203</a></li>
                    <li className="with-icon"><i className="phone"></i><a href="tel:571-483-8440">571-483-8440</a></li>
                  </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section copyright">
        <div className="container">
              <p className="is-left">&copy; {year} Monera Technologies. </p>
             {/* <p className="is-right">Created by <a href="https://wellpromotion.ba" target="_blank">Well Business Promotion</a></p> */}
        </div>
      </section>
      </>
)

export default Footer;