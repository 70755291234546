import React from "react"

import logo from '../images/monera-logo.png'


// const Header = ({ siteTitle }) => (

export default class Header extends React.Component {

  render(){
    return (



      <nav class="navbar" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <a class="navbar-item" href="/">
      <img src={logo} alt="Monera Tech Logo" />
    </a>

    <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false"
      data-target="navbarBasicExample">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="navbarBasicExample" class="navbar-menu">
    <div class="navbar-start">
      <a class="navbar-item" href="/about-us">
        About
      </a>

      <a class="navbar-item" href="/blog">
        Blog
      </a>

      <div class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link" href="/business-consulting">
        Business Consulting
        </a>
        <div class="navbar-dropdown">
          <a class="navbar-item" href="/business-consulting/business-analysis-and-value-targeting">
            Business Analysis and Value Targeting
          </a>
          <a class="navbar-item" href="/business-consulting/business-architecture">
            Business Architecture
          </a>
          <a class="navbar-item" href="/business-consulting/business-process-improvement">
            Business Process Improvement
          </a>
          <a class="navbar-item" href="/business-consulting/business-strategy">
          Business Strategy
          </a>
          <a class="navbar-item" href="/business-consulting/customer-experience-and-journey-mapping">
          Customer Experience and Journey Mapping
          </a>
          <a class="navbar-item" href="/business-consulting/enterprise-program-management">
          Enterprise Program Management
          </a>
          <a class="navbar-item" href="/business-consulting/operational-and-process-excellence">
          Operational and Process Excellence
          </a>
          <a class="navbar-item" href="/business-consulting/people-and-change-consulting">
          People and Change Consulting
          </a>
          <a class="navbar-item" href="/business-consulting/performance-measurement">
          Performance Measurement
          </a>
          <a class="navbar-item" href="/business-consulting/process-and-capability-assessment">
          Process and Capability Assessment
          </a>
          <a class="navbar-item" href="/business-consulting/vision-and-strategy-development">
          Vision and Strategy Development
          </a>
              </div>
              
              
            </div>
            <div class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link" href="/digital-consulting-services">
          Digital Consulting
        </a>

        <div class="navbar-dropdown">
          <a class="navbar-item" href="/digital-consulting-services/customer-experience-and-design">
            Customer Experience and Design
          </a>
          <a class="navbar-item" href="/digital-consulting-services/digital-strategy">
            Digital Strategy
          </a>
          <a class="navbar-item" href="/digital-consulting-services/digital-technology-services">
            Digital Technology Services
          </a>
          <a class="navbar-item" href="/digital-consulting-services/test-automation-and-devops-success">
            Test Automation and DevOps Success
          </a>
              </div>
              
              
            </div>
            <div class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link" href="/technology-services">
          Technology Services
        </a>

        <div class="navbar-dropdown">
          <a class="navbar-item" href="/technology-services/cloud-computing">
            Cloud Computing
          </a>
          <a class="navbar-item" href="/technology-services/data-and-analytics">
            Data and Analytics
          </a>
          <a class="navbar-item" href="/technology-services/devops">
            DevOps
          </a>
          <a class="navbar-item" href="/technology-services/enterprise-application-and-solutions">
            Enterprise Application and Solutions
          </a>
          <a class="navbar-item" href="/technology-services/enterprise-collaboration">
            Enterprise Collaboration
          </a>
          <a class="navbar-item" href="/technology-services/it-strategy">
            IT Strategy
          </a>
          <a class="navbar-item" href="/technology-services/mobile-app-development">
            Mobile App Development
          </a>
          <a class="navbar-item" href="/technology-services/modern-software-delivery">
            Modern Software Delivery
          </a>
          <a class="navbar-item" href="/technology-services/outsourcing">
            Outsourcing
          </a>
              </div>
              
              
      </div>
            
            
    </div>

    <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons">
          <a class="button gold" href="/contact-us">
            <strong>Contact Us</strong>
          </a>
        </div>
      </div>
    </div>
  </div>
</nav>
          
          

    )
  }

}